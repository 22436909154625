import React from 'react';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

// The .js references are necessary for requireJs to work in the browser.
import { IContentService, IContentListEntry } from '../services/ContentService';
import ContentListEntryComponent from './ContentListEntryComponent';
import { getCourseScheduleById, getCourseSkillQuestions } from '../../core/course/_requests'
import { CourseSkillQuestion, initialCourseSkillQuestions } from '../../core/course/_models'

export default class ContentList extends React.Component<{
    contentService: IContentService;
    contextId: string;
    asUserId: string;
}> {
    constructor(props: { contentService: IContentService, contextId: string, asUserId: string }) {
        super(props);

        this.state = {
            contentList: [],
            courseSkillQuestions: []
        };
        this.contentService = props.contentService;
    }

    public state: {
        contentList: IContentListEntry[];
        courseSkillQuestions: CourseSkillQuestion[];
    };

    protected contentService: IContentService;
    /**
     * Keeps track of newly created content to assign a key
     * @memberof ContentList
     */
    protected newCounter = 0;

    public async componentDidMount(): Promise<void> {
        await this.updateList();
        await this.updateCourseSkillQuestions();
    }

    public render(): React.ReactNode {
        return (
            <div>
                <ListGroup>
                    {this.state.contentList.filter(x => this.state.courseSkillQuestions.filter(y => y.questionId === x.contentId).length > 0).map((content, i) => (
                        <ContentListEntryComponent
                            contentService={this.contentService}
                            contextId={this.props.contextId}
                            asUserId={this.props.asUserId}
                            data={content}
                            key={content.originalNewKey ?? content.contentId}
                            onDiscard={() => this.onDiscard(content)}
                            onSaved={(newData) =>
                                this.onSaved(content, newData)
                            }
                            index={i}
                        ></ContentListEntryComponent>
                    ))}
                </ListGroup>
            </div>
        );
    }

    protected async updateCourseSkillQuestions(): Promise<void> {
        await getCourseScheduleById(Number(this.props.contextId)).then(async (value) => {
            await getCourseSkillQuestions('&courseId=' + value?.courseId).then((values) => {
                this.setState({ courseSkillQuestions: values.data })
            })
        })
    }

    protected async updateList(): Promise<void> {
        const contentList = await this.contentService.list();
        this.setState({ contentList: contentList });
    }

    protected new() {
        this.setState({
            contentList: [
                {
                    contentId: 'new',
                    mainLibrary: undefined,
                    title: 'New H5P',
                    originalNewKey: `new-${this.newCounter++}`
                },
                ...this.state.contentList
            ]
        });
    }

    protected onDiscard(content: IContentListEntry) {
        this.setState({
            contentList: this.state.contentList.filter((c) => c !== content)
        });
    }

    protected async onDelete(content: IContentListEntry) {
        if (!content.contentId) {
            return;
        }
        try {
            await this.contentService.delete(content.contentId);
            this.setState({
                contentList: this.state.contentList.filter((c) => c !== content)
            });
        } catch (error) {
            if (error instanceof Error) {
                console.error(error.message);
            }
        }
    }

    protected async onSaved(
        oldData: IContentListEntry,
        newData: IContentListEntry
    ) {
        this.setState({
            contentList: this.state.contentList.map((c) =>
                c === oldData ? newData : c
            )
        });
    }
}
