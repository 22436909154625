

import React, { useState, useEffect } from "react";
import { useIntl } from 'react-intl'

interface CountdownProps {
  endDate: string; // Format: "Month Day, Year HH:mm:ss"
  totalTime: number // minutes
  finishExam: () => void
  courseScheduleId: number
  userId: number
}

const UpcomingEventTimer: React.FC<CountdownProps> = ({ endDate, totalTime, finishExam, courseScheduleId, userId }) => {
  const intl = useIntl()
  const endDateTime = new Date().getTime() + totalTime

  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    //const endDateTime = new Date("August 23, 2025 17:00:00 PDT").getTime();
    const timeRemaining = endDateTime - now;
    localStorage.setItem("OPE_EXAM_REMAINING_TIME_" + courseScheduleId + "_" + userId, String(timeRemaining))
    if (timeRemaining > 0) {
      const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

      setCountdown({ days, hours, minutes, seconds });
    } else {
      setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      finishExam();
    }
  };

  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      calculateTimeLeft();
    }, 1000);

    return () => clearInterval(interval);
  }, [totalTime]);



  return (
    <div id="timer" className="flex-wrap d-flex justify-content-center">
      <div
        id="hours"
        className="align-items-center flex-column d-flex justify-content-center"
      >
        {countdown.hours} <span>{intl.formatMessage({ id: "Hour" })}</span>
      </div>
      <div
        id="minutes"
        className="align-items-center flex-column d-flex justify-content-center"
      >
        {countdown.minutes} <span>{intl.formatMessage({ id: "Minute" })}</span>
      </div>
      <div
        id="seconds"
        className="align-items-center flex-column d-flex justify-content-center"
      >
        {countdown.seconds} <span>{intl.formatMessage({ id: "Second" })}</span>
      </div>
    </div>
  );
};

export default UpcomingEventTimer;
