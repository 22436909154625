

import React, { useEffect, useState } from "react"
import { CourseSchedule } from '../../core/courseschedule/_models'
import { useIntl } from 'react-intl'

type Props = {
  courseSchedules: Array<CourseSchedule>
}

const OurHistoryContentOne: React.FC<Props> = ({courseSchedules}) => {
  const intl = useIntl()
  
  return (
    <>
      <div className="history-area ptb-10 bg-fafafb">
        <div className="container">
          <ol className="timeline history-timeline">
            {courseSchedules && courseSchedules.map((courseSchedule) =>
              <li className="timeline-block">
                <div className="timeline-date">
                  <span>{courseSchedule.start ? new Date(courseSchedule.start).toLocaleDateString() : '' }</span>
                  {courseSchedule.start ? new Date(courseSchedule.start).toLocaleTimeString() : '' }
                  <sup></sup>
                </div>

                <div className="timeline-icon">
                  <span className="dot-badge"></span>
                </div>

                <div className="timeline-content">
                  <div className="row align-items-center">
                    <div className="col-lg-7 col-md-12">
                      <div className="content">
                        <h3>{courseSchedule.title}</h3>
                        <p>
                           {courseSchedule.description}
                        </p>
                        { new Date() > new Date(courseSchedule?.start!) && 
                          new Date() < new Date(courseSchedule?.end!) &&
                        <p>
                          <a href={"/event/" + courseSchedule.courseId + "/" + courseSchedule.id} className="default-btn d-block w-100">
                            <i className="flaticon-stopwatch"></i>
                            {intl.formatMessage({ id: "Start" })} <span></span>
                          </a>
                        </p>
                        }
                      </div>
                    </div>

                    <div className="col-lg-5 col-md-12">
                      <div className="image">
                        <img
                          src="/img/history/history2.jpg"
                          alt="image"
                          width={750}
                          height={650}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            )}
          </ol>
        </div>
      </div>
    </>
  );
};

export default OurHistoryContentOne;
