import React, { useEffect } from "react";
import Navbar from "../../../components/Layouts/Navbar";
import PageBanner from "../../../components/Common/PageBanner";
import EventDetailsContent from "../../../components/Events/EventDetailsContent";
import EventSpeakers from "../../../components/Events/EventSpeakers";
import Footer from "../../../components/Layouts/Footer";
import { useParams } from 'react-router';
import { useAuth } from "../../../core/auth/Auth"
import { useNavigate } from "react-router-dom";

export default function Page() {
  const { courseId, courseScheduleId } = useParams();
  const { auth } = useAuth()
  const navigate = useNavigate();
  useEffect(() => {
    if(!auth)
      navigate("/login");
  }, [])
  return (
    <>
      {auth && courseScheduleId && <EventDetailsContent courseId={courseId} courseScheduleId={courseScheduleId}/>}
    </>
  );
}
