
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UpcomingEventTimer from "./UpcomingEventTimer";
import EventSidebar from "./EventSidebar";
import ContentListComponent from '../../_h5p_play/components/ContentListComponent'
import { ContentService } from '../../_h5p_play/services/ContentService'
import { Course } from '../../core/course/_models'
import { getCourseById } from '../../core/course/_requests'
import { useAuth } from '../../core/auth/Auth'
import { useIntl } from 'react-intl'
import Swal from "sweetalert2";

interface IProps {
  courseId: string | undefined
  courseScheduleId: string | undefined
}
const EventDetailsContent: React.FC<IProps> = ({ courseId, courseScheduleId }) => {
  const intl = useIntl()
  const [contentService, setContentService] = useState<ContentService>();
  const [course, setCourse] = useState<Course>();
  const navigate = useNavigate();
  const { currentUser, auth } = useAuth()
  const totalTime = localStorage.getItem("OPE_EXAM_REMAINING_TIME_" + courseScheduleId + "_" + currentUser!.id)
  const [showInstructions, setShowInstructions] = useState(true)
  const [showCompletingMessage, setShowCompletingMessage] = useState(false)

  useEffect(() => {
    getCourseById(Number(courseId)).then((value) => {
      setCourse(value)
      var service = new ContentService(`${process.env.REACT_APP_H5P_API_URL}/h5p`,
        value!.courseSkillQuestions!.map(courseSkillQuestion => String(courseSkillQuestion.questionId))
      )
      service.setCsrfToken(JSON.parse(String(localStorage.getItem('OpeOpraziH5PConfig'))).csrfToken)
      setContentService(service)
    })
  }, [])

  window.addEventListener("beforeunload", (ev) => {
    ev.preventDefault();
    return ev.returnValue = intl.formatMessage({ id: "ExitExamPageMessage" });
  });

  const finishExam = () => {
    setShowCompletingMessage(true)

    Swal.fire({
      title: intl.formatMessage({ id: "FinishExam" }),
      text: intl.formatMessage({ id: "CompletingExam" }),
      icon: "info",
      showCancelButton: false,
      showCloseButton: false,
      showConfirmButton: false
    })

    var iframes = document.getElementsByTagName("iframe");
    for (var j = 0; j < iframes.length; j++) {
      var innerDoc = iframes[j].contentDocument || iframes[j].contentWindow!.document;
      var links = innerDoc.getElementsByClassName('h5p-question-check-answer h5p-joubelui-button');

      console.log("links count " + links.length)
      for (var i = 0; i < links.length; i++) {
        (links[i] as HTMLElement).click();
      }
    }

    setTimeout(function () {
      navigate("/course-completed");
      Swal.close()
    }, 5000); //delay is in milliseconds 
  }

  return (
    <>
      <div className="events-details-area pb-100">
        <div className="events-details-image">
          <img
            src="/img/events/events-details.jpg"
            alt="image"
            width={1920}
            height={500}
          />

          {course && !showInstructions && <UpcomingEventTimer endDate="" totalTime={totalTime ? Number(totalTime) : Number(course?.duration) * 1000 * 60} finishExam={finishExam} courseScheduleId={Number(courseScheduleId)} userId={currentUser!.id} />}
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="events-details-header">
                <ul>
                  <li>
                    <i className="bx bx-map"></i>{course && course.name}
                  </li>
                  <li>
                    <i className="bx bx-time"></i>{course?.duration + ' ' + intl.formatMessage({ id: "Minute" })}
                  </li>
                </ul>
              </div>

              {showInstructions && !showCompletingMessage &&
                <>
                  <div className="events-details-desc">
                    {course && course.instructions}
                  </div>
                  <div className="events-details-desc">
                      <img src="/img/instructions/listening.png" width={77} />
                      Dinleme sorularında hoparlör simgesine tıklayarak dinlemeye başlayınız.
                  </div>
                  <div className="events-details-desc">
                      <img src="/img/instructions/drag.png" width={170} />
                      Sürükle bırak sorularında kutucuğun içerisine fare imlecini getirdiğinizde kutucuğun rengi koyu mavi olduğunda doğru cevabı kutucuğa bırakınız.
                  </div>
                  <div className="events-details-desc">
                      <img src="/img/instructions/push-button.png" width={172} />
                      Konuşma sorularında "Push to speak" butonuna basıp sonrasında cevabınızı söyleyiniz.
                  </div>
                  
                  <div className="row">
                    <a href="#" onClick={(e) => {
                      setShowInstructions(false)
                    }} className="default-btn">
                      <i className="flaticon-right"></i> {totalTime ? intl.formatMessage({ id: "ContinueExam" }) : intl.formatMessage({ id: "StartExam" })}
                      <span></span>
                    </a>
                  </div>
                </>
              }

              <div style={{ visibility: showInstructions || showCompletingMessage ? "hidden" : "visible" }}>
                <div className="events-details-desc">
                  {contentService && currentUser &&
                    <ContentListComponent
                      contentService={contentService}
                      contextId={String(courseScheduleId)}
                      asUserId={String(currentUser.id)}
                    ></ContentListComponent>}
                </div>
                <div className="row">
                  <a href="#" onClick={(e) => {
                    e.preventDefault()
                    finishExam()
                  }} className="default-btn">
                    <i className="flaticon-right"></i> {intl.formatMessage({ id: "FinishExam" })}{" "}
                    <span></span>
                  </a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default EventDetailsContent;
