import React from 'react';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';
import type { IContentMetadata, IEditorModel } from '@lumieducation/h5p-server';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFingerprint,
    faBookOpen,
    faWindowClose,
    faSave,
    faCheck,
    faPlay,
    faPencilAlt,
    faFileDownload,
    faTrashAlt,
    faCopyright,
    faHashtag,
    faUser,
    faLock
} from '@fortawesome/free-solid-svg-icons';

import { H5PEditorUI, H5PPlayerUI } from '@lumieducation/h5p-react';

import { IContentListEntry, IContentService } from '../services/ContentService';
import './ContentListEntryComponent.css';
import {
    IxAPIEvent
} from '@lumieducation/h5p-webcomponents';

export default class ContentListEntryComponent extends React.Component<{
    contentService: IContentService;
    data: IContentListEntry;
    onDiscard: (content: IContentListEntry) => void;
    onSaved: (data: IContentListEntry) => void;
    index: number;
    contextId: string;
    asUserId: string;
}> {
    constructor(props: {
        contentService: IContentService;
        data: IContentListEntry;
        onDiscard: (content: IContentListEntry) => void;
        onSaved: (data: IContentListEntry) => void;
        index: number;
        contextId: string;
        asUserId: string;
    }) {
        super(props);
        this.state = {
            editing: props.data.contentId === 'new',
            contextId: props.contextId,
            asUserId: props.asUserId,
            playing: true,
            saving: false,
            saved: false,
            loading: true,
            saveErrorMessage: '',
            saveError: false,
            showingCustomCopyright: false,
            showContextIdModal: false,
            showAsUserIdModal: false,
            readOnlyState: false
        };
        this.h5pEditor = React.createRef();
        this.saveButton = React.createRef();
        this.h5pPlayer = React.createRef();
        this.contextIdInput = React.createRef();
        this.asUserIdSelect = React.createRef();
    }

    public state: {
        asUserId?: string;
        contextId?: string;
        editing: boolean;
        loading: boolean;
        playing: boolean;
        readOnlyState: boolean;
        saved: boolean;
        saveError: boolean;
        saveErrorMessage: string;
        saving: boolean;
        showAsUserIdModal: boolean;
        showContextIdModal: boolean;
        showingCustomCopyright: boolean;
    };

    private h5pPlayer: React.RefObject<H5PPlayerUI>;
    private h5pEditor: React.RefObject<H5PEditorUI>;
    private saveButton: React.RefObject<HTMLButtonElement>;
    private contextIdInput: React.RefObject<HTMLInputElement>;
    private asUserIdSelect: React.RefObject<HTMLSelectElement>;

    public render(): React.ReactNode {
        return (
            <ListGroupItem
                key={
                    this.props.data.originalNewKey ?? this.props.data.contentId
                }
            >
                <Container>
                    <Row>
                        <Col className="p-2">
                            <h5>{this.props.index+1} {"."} {this.props.data.title}</h5>
                        </Col>
                    </Row>
                </Container>
                {this.state.playing && this.props.contentService ? (
                    <div className={this.state.loading ? 'loading' : ''}>
                        <H5PPlayerUI
                            ref={this.h5pPlayer}
                            contentId={this.props.data.contentId}
                            contextId={this.state.contextId || undefined}
                            asUserId={this.state.asUserId || undefined}
                            readOnlyState={this.state.readOnlyState}
                            loadContentCallback={
                                this.props.contentService.getPlay
                            }
                            onInitialized={this.onPlayerInitialized}
                            onxAPIStatement={(
                                statement: any,
                                context: any,
                                event: IxAPIEvent
                            ) => <></>}
                        />
                        <div
                            style={{
                                visibility: this.state.loading
                                    ? 'visible'
                                    : 'collapse'
                            }}
                            className="spinner-border spinner-border-sm m-2"
                            role="status"
                        ></div>
                    </div>
                ) : undefined}
            </ListGroupItem>
        );
    }

    protected play() {
        this.setState({ editing: false, playing: true });
    }
    protected close() {
        this.setState({ editing: false, playing: false });
    }

    private onPlayerInitialized = () => {
        this.setState({ loading: false });
    };

    protected async save() {
        this.setState({ saving: true });
        try {
            const returnData = await this.h5pEditor.current?.save();
            if (returnData) {
                await this.props.onSaved({
                    contentId: returnData.contentId,
                    mainLibrary: returnData.metadata.mainLibrary,
                    title: returnData.metadata.title,
                    originalNewKey: this.props.data.originalNewKey
                });
            }
        } catch (error) {
            // We ignore the error, as we subscribe to the 'save-error' and
            // 'validation-error' events.
        }
    }

    protected onSaveError = async (event: string) => {
        console.log("event " + event)
        this.setState({
            saving: false,
            saved: false,
            saveError: true,
            saveErrorMessage: event // TODO
        });
        setTimeout(() => {
            this.setState({
                saveError: false
            });
        }, 5000);
    };

    protected onSaved = async (contentId: string, metadata: IContentMetadata) => {
        this.setState({
            saving: false,
            saved: true
        });
        setTimeout(() => {
            this.setState({ saved: false });
        }, 3000);
    };

    protected setContextId = () => {
        this.setState({
            contextId: this.contextIdInput.current?.value,
            showContextIdModal: false
        });
    };
}
