

import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../base/models'
import {Course, CoursesQueryResponse, CourseSkillQuestion, CourseSkillQuestionsQueryResponse, CourseSchedulesQueryResponse, CourseSchedule} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_COURSE_URL = `${API_URL}/course/getcoursedetail`
const GET_COURSES_URL = `${API_URL}/course/getcourses`
const GET_EXAMS_URL = `${API_URL}/course/getexams`
const GET_DEMO_COURSES_URL = `${API_URL}/course/getdemocourses`
const GET_DEMO_EXAMS_URL = `${API_URL}/course/getdemoexams`
const GET_COURSESKILLQUESTION_URL = `${API_URL}/courseskillquestion/getcourseskillquestion`
const GET_COURSESKILLQUESTIONS_URL = `${API_URL}/courseskillquestion/getcourseskillquestions`
const GET_COURSESCHEDULE_URL = `${API_URL}/courseschedule/getcourseschedule`
const GET_COURSESCHEDULES_URL = `${API_URL}/courseschedule/getcourseschedules`

const getCourses = (query: string): Promise<CoursesQueryResponse> => {
  return axios
    .get(`${GET_COURSES_URL}?${query}`)
    .then((d: AxiosResponse<CoursesQueryResponse>) => d.data)
}

const getExams = (query: string): Promise<CoursesQueryResponse> => {
  return axios
    .get(`${GET_EXAMS_URL}?${query}`)
    .then((d: AxiosResponse<CoursesQueryResponse>) => d.data)
}


const getDemoCourses = (query: string): Promise<CoursesQueryResponse> => {
  return axios
    .get(`${GET_DEMO_COURSES_URL}?${query}`)
    .then((d: AxiosResponse<CoursesQueryResponse>) => d.data)
}

const getDemoExams = (query: string): Promise<CoursesQueryResponse> => {
  return axios
    .get(`${GET_DEMO_EXAMS_URL}?${query}`)
    .then((d: AxiosResponse<CoursesQueryResponse>) => d.data)
}

const getCourseById = (id: ID): Promise<Course | undefined> => {
  return axios
    .get(`${GET_COURSE_URL}/${id}`)
    .then((response: AxiosResponse<Response<Course>>) => response.data)
    .then((response: Response<Course>) => response.data)
}

const getCourseSkillQuestions = (query: string): Promise<CourseSkillQuestionsQueryResponse> => {
  return axios
    .get(`${GET_COURSESKILLQUESTIONS_URL}?${query}`)
    .then((d: AxiosResponse<CourseSkillQuestionsQueryResponse>) => d.data)
}

const getCourseSkillQuestionById = (id: ID): Promise<CourseSkillQuestion | undefined> => {
  return axios
    .get(`${GET_COURSESKILLQUESTION_URL}/${id}`)
    .then((response: AxiosResponse<Response<CourseSkillQuestion>>) => response.data)
    .then((response: Response<CourseSkillQuestion>) => response.data)
}

const getCourseSchedules = (query: string): Promise<CourseSchedulesQueryResponse> => {
  return axios
    .get(`${GET_COURSESCHEDULES_URL}?${query}`)
    .then((d: AxiosResponse<CourseSchedulesQueryResponse>) => d.data)
}

const getCourseScheduleById = (id: ID): Promise<CourseSchedule | undefined> => {
  return axios
    .get(`${GET_COURSESCHEDULE_URL}/${id}`)
    .then((response: AxiosResponse<Response<CourseSchedule>>) => response.data)
    .then((response: Response<CourseSchedule>) => response.data)
}

export {
  getCourses, 
  getExams, 
  getDemoCourses, 
  getDemoExams, 
  getCourseById, 
  getCourseSkillQuestions, 
  getCourseSkillQuestionById,
  getCourseSchedules,
  getCourseScheduleById
}
