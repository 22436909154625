

import React, {FC, createContext, useContext, useState} from 'react'
import {WithChildren} from '../base/react18MigrationHelpers'
import {isNotEmpty} from '../base/helpers'
import { H5PUserModel, initialH5PUser } from './_models'

const H5P_CONFIG_KEY = process.env.REACT_APP_H5P_CONFIG_KEY || 'OpeOpraziH5PConfig'


const initialState = {
  user: initialH5PUser
}

function getConfig() {
  const h5pConfig = localStorage.getItem(H5P_CONFIG_KEY)
  if (isNotEmpty(h5pConfig)) {
    try {
      return JSON.parse(String(h5pConfig))
    } catch (er) {
      console.error(er)
    }
  }
  return ''
}

// Side effect
export function setAuthH5P(user: H5PUserModel | undefined) {
  localStorage.setItem(H5P_CONFIG_KEY, JSON.stringify(user))
}


const H5PContext = createContext(initialState)

const useAuthH5P = () => {
  return useContext(H5PContext)
}

const H5PProvider: FC<WithChildren> = ({children}) => {
  const user = getConfig()
  return <H5PContext.Provider value={{user : user}}>{children}</H5PContext.Provider>
}

export {H5PProvider, useAuthH5P}
