
  
import React from "react";
import { useIntl } from 'react-intl'
import { useAuth } from "../../core/auth/Auth";

const HowItWork: React.FC = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  return (
    <>
      <div className="how-its-work-area ptb-10">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="how-its-work-content">
                <span className="sub-title">
                  <img
                    src="/img/star-icon.png"
                    alt="image"
                    width={32}
                    height={34}
                  />
                  {currentUser?.fullName} - {intl.formatMessage({ id: "YourSuccesses" })}
                </span>

                <h2>{intl.formatMessage({ id: "AchievementsandSuccesses" })}</h2>
                <p>
                {intl.formatMessage({ id: "AchievementsandSuccessesContent" })}
                </p>

                <div className="inner-box">
                  <div className="single-item">
                    <div className="count-box">1</div>
                    <h3>{intl.formatMessage({ id: "ExamSuccess" })}</h3>
                    <p>
                      {intl.formatMessage({ id: "ExamSuccessNoResult" })}
                    </p>
                  </div>

                  <div className="single-item">
                    <div className="count-box">2</div>
                    <h3>{intl.formatMessage({ id: "CourseSuccess" })}</h3>
                    <p>
                    {intl.formatMessage({ id: "CourseSuccessNoResult" })}
                    </p>
                  </div>

                  <div className="single-item">
                    <div className="count-box">3</div>
                    <h3>{intl.formatMessage({ id: "Achievements" })}</h3>
                    <p>
                    {intl.formatMessage({ id: "AchievementsNoResult" })}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="how-its-work-image">
                <img
                  src="/img/how-its-work.png"
                  alt="image"
                  width={715}
                  height={528}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowItWork;
